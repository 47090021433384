<script setup>
import {Link, useForm} from "@inertiajs/vue3";
import AuthenticationCard from "@/Components/Profile/AuthenticationCard.vue";
import AuthenticationCardLogo from "@/Components/Profile/AuthenticationCardLogo.vue";
import Checkbox from "@/Components/Profile/Checkbox.vue";
import InputError from "@/Components/Profile/InputError.vue";
import InputLabel from "@/Components/Profile/InputLabel.vue";
import PrimaryButton from "@/Components/Profile/PrimaryButton.vue";
import TextInput from "@/Components/Profile/TextInput.vue";
import AuthLayout from "@/Layouts/AuthLayout.vue";

defineProps({
  canResetPassword: Boolean,
  status: String,
});

const form = useForm({
  email: "",
  password: "",
  remember: false,
});

const submit = () => {
  form.transform((data) => ({
    ...data,
    remember: form.remember ? "on" : "",
  })).post(route("login"), {
    onFinish: () => form.reset("password"),
  });
};
</script>

<template>
  <AuthLayout title="Login">
    <AuthenticationCard>
      <template #logo>
        <AuthenticationCardLogo/>
      </template>

      <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
        {{ status }}
      </div>

      <form @submit.prevent="submit">
        <div>
          <InputLabel for="email" value="Email"/>
          <TextInput
              id="email"
              v-model="form.email"
              type="email"
              class="mt-1 block w-full"
              required
              autofocus
              autocomplete="username"
          />
          <InputError class="mt-2" :message="form.errors.email"/>
        </div>

        <div class="mt-4">
          <InputLabel for="password" value="Passwort"/>
          <TextInput
              id="password"
              v-model="form.password"
              type="password"
              class="mt-1 block w-full"
              required
              autocomplete="current-password"
          />
          <InputError class="mt-2" :message="form.errors.password"/>
        </div>

        <div class="mt-4 flex justify-between">
          <label class="flex items-center">
            <Checkbox
                v-model:checked="form.remember"
                name="remember"
            />
            <span class="ms-2 text-sm">Eingeloggt bleiben
            </span>
          </label>

          <PrimaryButton
              class="ms-4"
              :class="{ 'opacity-25': form.processing }"
              :disabled="form.processing"
          >
            Login
          </PrimaryButton>
        </div>

        <div class="flex items-center justify-center mt-6 mb-2">
          <Link
              v-if="canResetPassword"
              :href="route('password.request')"
              class="underline text-sm hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            Passwort vergessen?
          </Link>
        </div>
      </form>

      <template #footer>
        <div class="flex flex-col items-center justify-end mt-8">
          <p class="text-base">Sie haben noch keinen Account?</p>

          <Link
              :href="route('register')"
              class="underline text-primary hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            zur Anmeldung
          </Link>
        </div>
      </template>
    </AuthenticationCard>
  </AuthLayout>
</template>
